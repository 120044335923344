import React from 'react';
import Account from 'modules/Account';
import Layout from 'modules/Layout';
import SessionStorage from 'libs/SessionStorage';
import { LOGIN_REDIRECT_TO_KEY } from 'modules/App/constants';


function action({ route }) {

  SessionStorage.setItem(LOGIN_REDIRECT_TO_KEY, '/');

  return {
    chunks   : ['account'],
    name     : route.name,
    component: (
      <Layout.components.PlainLayout>
        <Account.components.SignOutSuccessStatement />
      </Layout.components.PlainLayout>
    ),
  };

}

export default action;
